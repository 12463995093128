import React from 'react';
import { ScrollAreaProps as RadixScrollAreaProps } from '@radix-ui/react-scroll-area';
import { StyledScrollArea, StyledViewport, StyledScrollbar, StyledThumb } from './styled';

type ScrollAreaProps = RadixScrollAreaProps & {
    children?: React.ReactNode;
};

/**
 * Container for scrollable content allowing custom scrollbar.
 */
export const ScrollArea = ({ children, ...rest }: ScrollAreaProps) => {
    return (
        <StyledScrollArea type="auto" {...rest}>
            <StyledViewport children={children} />
            <StyledScrollbar orientation="vertical">
                <StyledThumb />
            </StyledScrollbar>
            {/* <StyledScrollbar orientation="vertical">
                <StyledThumb />
            </StyledScrollbar> */}
            <StyledScrollbar orientation="horizontal">
                <StyledThumb />
            </StyledScrollbar>
        </StyledScrollArea>
    );
};
