import * as ScrollArea from '@radix-ui/react-scroll-area';
import styled from '@emotion/styled';

export const StyledScrollArea = styled(ScrollArea.Root)({
    overflow: 'hidden',
});

export const StyledViewport = styled(ScrollArea.Viewport)({
    width: '100%',
    height: '100%',
    borderRadius: 'inherit',
});

export const StyledThumb = styled(ScrollArea.Thumb)(({ theme }) => ({
    flex: 1,
    background: theme.colors.grey80,
    borderRadius: 3,
    // increase target size for touch devices https://www.w3.org/WAI/WCAG21/Understanding/target-size.html
    position: 'relative',
    cursor: 'grab',
    '&:active': {
        cursor: 'grabbing',
    },
    '&::before': {
        content: '""',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100%',
        height: '100%',
        minWidth: 44,
        minHeight: 44,
    },
}));

export const StyledScrollbar = styled(ScrollArea.Scrollbar)(({ theme }) => ({
    display: 'flex',
    margin: 3,
    backgroundColor: theme.colors.grey40,
    // ensures no selection
    userSelect: 'none',
    // disable browser handling of all panning and zooming gestures on touch devices
    touchAction: 'none',
    transition: 'background 160ms ease-out',
    // '&:hover': { background: theme.colors.grey20 },
    '&[data-orientation="vertical"]': { width: 3 },
    '&[data-orientation="horizontal"]': {
        flexDirection: 'column',
        height: 3,
    },
}));
