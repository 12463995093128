import { Text, TextProps } from '$shared/components/Text';
import { Ref, forwardRef } from 'react';
import { useModule } from '../../hooks/useModule';

export type HeadlineProps = TextProps & {
    children?: React.ReactNode;
};

export const Headline = forwardRef((props: HeadlineProps, ref: Ref<HTMLParagraphElement>) => {
    const { index = 0 } = useModule();
    const headlineTag = index === 0 ? 'h1' : 'h2';

    return <Text as={props.as ?? headlineTag} {...props} ref={ref} />;
});

Headline.displayName = 'Headline';
