export const getPathname = (url: string) => {
    const hasProtocol = url.startsWith('http');
    let domain = '';
    if (!hasProtocol) {
        // the host/domain doesn't matter since we will only return pathname
        domain = 'https://example.com';
    }
    try {
        const urlObject = new URL(domain + url);
        return urlObject.pathname;
    } catch (err) {
        console.error(err);
    }
    return '/';
};
