import { useTheme } from '@emotion/react';
import { useMemo, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

interface Props {
    children: React.ReactNode;
    className?: string;
    maxZIndex?: boolean;
}

export const Portal = ({ children, className, maxZIndex = true }: Props) => {
    const [mounted, setMounted] = useState(false);
    const theme = useTheme();
    useEffect(() => {
        setMounted(true);

        return () => setMounted(false);
    }, []);

    const el = useMemo(() => {
        return mounted ? window.document && window.document.createElement('div') : undefined;
    }, [mounted]);

    useEffect(() => {
        if (el && mounted) {
            const target = window.document.body;
            const classList = ['portal-container'];
            if (className) className.split(' ').forEach((item) => classList.push(item));
            classList.forEach((item) => el.classList.add(item));
            const zIndex = maxZIndex ? theme.zIndexes.portalHigh : theme.zIndexes.portalLow;
            el.style.zIndex = `${zIndex}`;
            el.style.position = 'relative';
            target.appendChild(el);

            return () => {
                target.removeChild(el);
            };
        }
    }, [mounted, el, className]);

    return mounted && el ? createPortal(children, el) : null;
};
