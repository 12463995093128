import { Frame } from '~/lib/data-contract';
import { fetcher } from './fetcher';
import { publicRuntimeConfig } from '~/shared/utils/public-variables';
import { QueryParams } from '~/shared/utils/request/utils/buildURL';

/**
 * Helper method for requesting a frame from the api.
 * Returns headers, status and data
 *
 * NOTE: Currently the frame request expects an url.
 * We simply pass the culture as url.
 */
interface FetchFrameParams {
    culture: string;
    market: string;
    params: QueryParams;
}
export async function fetchFrame({ culture, market, params = {} }: FetchFrameParams) {
    const { API_URL } = publicRuntimeConfig();
    const endpointUrl = `${API_URL}/api/content/frame`;

    return await fetcher<Frame>(`${endpointUrl}`, {
        url: `/${culture}/${market}`,
        ...params,
    }).then((res) => res.json());
}
