import styled from '@emotion/styled';
import { ifProp, switchProp } from 'styled-tools';
import { breakpoints } from '~/theme';
import { StyledScrollArea } from '../ScrollArea/styled';

export const StyledBackdrop = styled.div<{ transparentBackdrop?: boolean }>(
    {
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        opacity: 0,
        transition: 'all 300ms cubic-bezier(0.4, 0, 0.2, 1)',
        transitionDelay: '300ms',
    },
    ifProp(
        'transparentBackdrop',
        {
            backgroundColor: 'none',
        },
        {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
    ),
);

export const StyledContent = styled.div<{ maxWidth: number; withBoxShadow: boolean }>(
    ({ theme, maxWidth, withBoxShadow }) => ({
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        top: 0,
        width: '100%',
        maxWidth: maxWidth,
        height: '100%',
        boxSizing: 'border-box',
        backgroundColor: theme.colors.white100,
        transition: 'all 300ms cubic-bezier(0.4, 0, 0.2, 1)',
        boxShadow: withBoxShadow ? theme.elevations.sidePanel : 'none',
        [`${StyledScrollArea}`]: {
            height: '100%',
        },
    }),
);

export const Wrapper = styled.div<{ open: boolean; direction: 'left' | 'right' }>(
    {
        position: 'fixed',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        border: 0,
        backgroundColor: 'transparent',
    },
    switchProp('direction', {
        left: () => ({
            [`${StyledContent}`]: {
                left: '100%',
            },
        }),
        right: () => ({
            [`${StyledContent}`]: {
                right: '100%',
            },
        }),
    }),
    ifProp('open', ({ direction }) => ({
        [`${StyledBackdrop}`]: {
            transitionDuration: '250ms',
            transitionDelay: '0ms',
            opacity: 1,
        },
        [`${StyledContent}`]: {
            transform: `translate3d(${direction === 'left' ? '-100%' : '100%'}, 0, 0)`,
        },
    })),
);

export const StyledInner = styled.div<{ sidePadding: string }>(
    ({ theme }) => ({
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'flex-start',
        padding: `0 ${theme.spaces[5]} ${theme.spaces[9]} ${theme.spaces[5]}`,
    }),
    switchProp('sidePadding', {
        ['default']: ({ theme }) => ({
            [breakpoints.sm]: {
                padding: `0 ${theme.spaces[8]} ${theme.spaces[2]}`,
            },
        }),
        ['none']: ({ theme }) => ({
            padding: `0 0 ${theme.spaces[2]}`,
        }),
        ['large']: ({ theme }) => ({
            [breakpoints.sm]: {
                padding: `0 8rem ${theme.spaces[2]}`,
            },
        }),
    }),
);

export const StyledHeader = styled.div(({ theme }) => ({
    padding: `${theme.spaces[7]} ${theme.spaces[5]}`,
    [breakpoints.sm]: {
        padding: `${theme.spaces[7]} ${theme.spaces[8]}`,
    },
}));

export const StyledFooter = styled.div<{ sidePadding: string }>(
    ({ theme }) => ({
        paddingTop: `${theme.spaces[5]}`,
        paddingBottom: `${theme.spaces[5]}`,
        paddingLeft: `${theme.spaces[5]}`,
        paddingRight: `${theme.spaces[5]}`,
        display: 'flex',
        flexDirection: 'row',
        columnGap: 20,
        marginTop: 'auto',
        [breakpoints.sm]: {
            paddingTop: `${theme.spaces[7]}`,
            paddingBottom: `${theme.spaces[7]}`,
        },
    }),
    switchProp('sidePadding', {
        ['default']: ({ theme }) => ({
            [breakpoints.sm]: {
                paddingLeft: `${theme.spaces[8]}`,
                paddingRight: `${theme.spaces[8]}`,
            },
        }),
        ['none']: () => ({
            paddingLeft: 0,
            paddingRight: 0,
        }),
        ['large']: () => ({
            [breakpoints.sm]: {
                paddingLeft: '6rem',
                paddingRight: '6rem',
            },
        }),
    }),
);

export const StyledActions = styled.div(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    paddingBottom: `${theme.spaces[5]}`,
}));

export const StyledCloseButton = styled.button(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: theme.spaces[6],
    height: theme.spaces[6],
    padding: 0,
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    color: theme.colors.black,
}));
