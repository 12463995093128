import { CSSObject } from '@emotion/react';
import { create } from 'zustand';
import { isDevice } from './utils';

type UseScrollLockState = {
    // The current open state
    isLocked: boolean;

    // Specify the open state
    setIsLocked: (isLocked: boolean) => void;

    // Locked scroll position
    scrollY: number;
    setScrollY: (scrollY: number) => void;

    lock: () => void;
    unlock: () => void;
};

const deviceLockStyles: CSSObject = {
    overflow: 'hidden',
    touchAction: 'none',
};

const desktopLockStyles: CSSObject = {
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    overflowY: 'scroll',
};

const scrollUnlockStyles = {
    overflow: '',
    touchAction: '',
    position: '',
    top: '',
    bottom: '',
    left: '',
    right: '',
};

export const useScrollLock = create<UseScrollLockState>((set, get) => ({
    isLocked: false,
    setIsLocked: (isLocked: boolean) => set({ isLocked }),
    scrollY: 0,
    setScrollY: (scrollY: number) => set({ scrollY }),

    lock: () => {
        const scrollY = window.scrollY;
        const doc = document.documentElement;

        set({ isLocked: true, scrollY });

        if (isDevice()) {
            Object.assign(doc.style, deviceLockStyles);
            return;
        }

        Object.assign(doc.style, desktopLockStyles);
        doc.style.top = `-${scrollY}px`;
    },

    unlock: () => {
        const state = get();
        if (!state.isLocked) return;
        const doc = document.documentElement;

        set({ isLocked: false, scrollY: 0 });

        Object.assign(doc.style, scrollUnlockStyles);

        if (isDevice()) {
            return;
        }

        window.scrollTo(0, state.scrollY);
    },
}));
